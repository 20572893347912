<template>
  <div class="px-8 mt-4">
    <v-dialog
      v-model="dialogAddItem"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="ppmpModal"
      scrollable
    >
      <v-card>
        <v-toolbar max-height="65" v-if="ppmpData.PPMPID" dark color="green darken-2">
          <v-toolbar-title
            >{{ ppmpData.PPMPDescription }}
            <span v-if="ppmpData.PPMPTypeID == 1">(DBM)</span>
            <span v-if="ppmpData.PPMPTypeID == 2">(NON-DBM)</span>
            <span v-if="ppmpData.PPMPTypeID  == 3">(LIB)</span>
            </v-toolbar-title
          >
          <v-spacer></v-spacer>
          <span
            style="width: 300px"
            v-if="!(ppmpData.Amount > 0) && ppmpData.PPMPStatusID == 11"
          >
            Total <span class="hidden-sm-and-down">Balance </span>: ₱ {{ formatPrice(ppmpData.budgetAmount) }}<br />
            Available <span class="hidden-sm-and-down">Balance </span> : ₱
            {{
              formatPrice(
                parseFloat(ppmpData.budgetAmount) - parseFloat(ppmpUsedAmount)
              )
            }}
          </span>
          <span style="width: 300px" v-else>
            Total <span class="hidden-sm-and-down">Balance </span>: ₱ {{ formatPrice(ppmpData.Amount) }} <br />
            Available <span class="hidden-sm-and-down">Balance </span>: ₱
            {{
              formatPrice(
                parseFloat(ppmpData.Amount) - parseFloat(ppmpUsedAmount)
              )
            }}
          </span>
          <v-btn icon dark @click="clickCloseMainDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <!-----Stepper----->
        <div>

<!-- <v-stepper alt-labels>
            <v-stepper-header>
              <v-stepper-step
                color="green"
                step="1"
                class="pa-3 pl-md-16"
                :complete="prData.FinanceStatusID == 7"
              >
              <span class="text-no-wrap">Funds Availability</span>
              <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(prData.FinaceApprovedDate)}}</small>
              </v-stepper-step>

              <v-divider class="my-6"></v-divider>

              <v-stepper-step
                color="green"
                :complete="prData.PresidentStatusID == 7"
                step="2"
                class="pa-3 pr-md-16"
              >
                <span class="text-no-wrap">College President Approval</span>
                <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(prData.PresidentApprovedDate)}}</small>
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper> -->

          <v-stepper alt-labels>
            <v-stepper-header>
              <v-stepper-step
                color="green"
                step="1"
                :complete="ppmpData.RecommendingApprovalStatusID == 7"
                class="pa-3"
              >
                <span class="text-no-wrap">Recommending Approval</span>
                <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(ppmpData.RecommendingApprovalDate)}}</small>
              </v-stepper-step>

              <v-divider class="my-6"></v-divider>

              <v-stepper-step
                color="green"
                step="2"
                :complete="ppmpData.BudgetStatusID == 7"
                class="pa-3"
              >
              <span class="text-no-wrap">Budget Officer Approval</span>
              <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(ppmpData.BudgetApprovedDate)}}</small>
              </v-stepper-step>

              <v-divider class="my-6"></v-divider>

              <v-stepper-step
                color="green"
                step="3"
                :complete="ppmpData.FinanceStatusID == 7"
                class="pa-3"
              >
                <span class="text-no-wrap">Funds Availability</span>
                <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(ppmpData.FinanceApprovedDate)}}</small>
              </v-stepper-step>

              <v-divider class="my-6"></v-divider>

              <v-stepper-step
                color="green"
                :complete="ppmpData.PresidentStatusID == 7"
                step="4"
                class="pa-3"
              >
                <span class="text-no-wrap">College President Approval</span>
                <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(ppmpData.PresidentApprovedDate)}}</small>
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </div>

        <v-alert
          v-if="ppmpData.isPending && ppmpData.PresidentStatusID != 7"
          border="left"
          type="error"
          class="white--text amber accent-4"
          prominent
          dense
        >
          <div v-if="ppmpData.RecommendingApprovalRemarks"> <strong>Recommending Approval: </strong>{{ ppmpData.RecommendingApprovalRemarks }}</div>
          <div v-if="ppmpData.BudgetRemarks"> <strong>Budget Officer: </strong>{{ ppmpData.BudgetRemarks }}</div>
          <div v-if="ppmpData.FinanceRemarks"> <strong>Finance Director: </strong>{{ ppmpData.FinanceRemarks }}</div>
          <div v-if="ppmpData.PresidentRemarks"> <strong>College President: </strong>{{ ppmpData.PresidentRemarks }}</div>
        </v-alert>

        <!-----Header----->
        <v-card-text style="max-height: 2000px">
        <div>
          <v-row class="mt-4">
            <v-col cols="12" class="d-flex justify-center">
              <v-subheader class="font-weight-bold text-h6 text-center"
                >Project Procurement Management Plan (PPMP)</v-subheader
              >
            </v-col>
            <v-col cols="12" class="d-flex justify-center mt-n8">
              <v-subheader
                >{{ ppmpData.DateStart }} - {{ ppmpData.DateEnd }}</v-subheader
              >
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-5">
            <v-col cols="12" sm="6" md="2">
              <v-subheader class="font-weight-bold">Delivery Unit:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-subheader>{{
                ppmpDetails.DeliveryUnitDescription
              }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="2">
              <v-subheader class="font-weight-bold"
                >Office/ Committee:</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-subheader>{{ ppmpDetails.OfficeDescription }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="2">
              <v-subheader class="font-weight-bold"
                >Program/ Activity/ Project (PAP) Title:</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-subheader>{{ ppmpDetails.PurposeDescription }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="2">
              <v-subheader class="font-weight-bold">Fund Source:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-subheader>{{ ppmpDetails.FundCode }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-5">
            <v-col cols="12" sm="6" md="2">
              <v-subheader class="font-weight-bold"
                >Specific Fund Source/ Fee:</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-subheader>{{
                ppmpDetails.SpecificFundSourceDescription
              }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="2">
              <v-subheader class="font-weight-bold">Budget Code:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-subheader>{{ ppmpDetails.BudgetCode }}</v-subheader>
            </v-col>
          </v-row>
        </div>

        <v-row>
          <v-col lg="12" class="py-4">
            <v-card class="">
              <v-data-table
                :loading="loading"
                :headers="headers"
                :items="itemList"
                :options.sync="options"
                :server-items-length="totalCount"
                :footer-props="footerProps"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2"
                      >PPMP Item List</span
                    >
                    <v-spacer></v-spacer>
                    <v-switch
                      v-if="$store.state.user.roleID == 7"
                      v-model="ppmpData.isOpen"
                      @change="swithcChange()"
                      label="PPMP Status"
                      class="mr-4 mt-6"
                    ></v-switch>
                    <v-btn
                      outlined
                      color="red darken-2"
                      dark
                      @click="print(ppmpData)"
                      class="mr-2"
                    >
                      <v-icon>print</v-icon>
                      Print
                    </v-btn>
                    <v-btn
                      outlined
                      color="red darken-2"
                      dark
                      v-if="ppmpData.parentPPMPID > 0 && ppmpData.PPMPTypeID == 2"
                      @click="printWithOldPPMP(ppmpData)"
                      class="mr-2"
                    >
                      <v-icon>print</v-icon>
                      Print  W/ Old PPMP
                    </v-btn>
                    <v-btn
                      outlined
                      color="red darken-2"
                      dark
                      v-if="ppmpData.parentPPMPID > 0 && ppmpData.PPMPTypeID == 3"
                      @click="printWithOldLIB(ppmpData)"
                      class="mr-2"
                    >
                      <v-icon>print</v-icon>
                      Print  W/ Old LIB
                    </v-btn>
                    <v-btn
                      class="white--text mr-2"
                      color="green"
                      v-if="ppmpData.PPMPStatusID == 11 && ppmpData.isOpen == 1 && whoCanUse() && parseFloat((parseFloat(ppmpData.Amount) - parseFloat(ppmpUsedAmount)).toFixed(2)) > 0"
                      @click="addItemBtn()"
                    >
                      <v-icon>mdi-plus</v-icon>
                      Add Item
                    </v-btn>

                    <span
                      v-if="ppmpData.isDBM && parseFloat(ppmpData.Amount) == 0"
                    >
                      <v-btn
                        class="white--text"
                        color="light-blue darken-4"
                        v-if="whoCanUse() && ppmpData.PPMPStatusID != 12 && ppmpData.isOpen == 1"
                        @click="publish(ppmpData)"
                      >
                        <v-icon>mdi-check</v-icon>
                        Publish
                      </v-btn>
                    </span>

                    <span v-else>
                      <v-btn
                        class="white--text"
                        color="light-blue darken-4"
                        v-if="
                          formatPrice(
                            parseFloat(ppmpData.Amount) -
                              parseFloat(ppmpUsedAmount)
                          ) == 0 && whoCanUse() && ppmpData.PPMPStatusID != 12 && ppmpData.isOpen == 1
                        "
                        @click="publish(ppmpData)"
                      >
                        <v-icon>mdi-check</v-icon>
                        Publish
                      </v-btn>
                    </span>
                    <v-btn
                      color="red darken-2"
                      class="white--text"
                      v-if="(ableToUnpublish() && $store.state.user.roleID == 7 && ppmpData.PPMPStatusID == 12) || $store.state.user.roleID == 1"
                      @click="unpublish(ppmpData.PPMPID)"
                    >
                      Unpublish
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.Qty`]="{ item }">
                  <span class="text-no-wrap">{{ formatQty(item.Qty || 0) }}</span>
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost || 0) }}</span>
                </template>
                <template v-slot:[`item.Amount`]="{ item }">
                  <span class="text-no-wrap">₱ {{formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty))}}</span>
                </template>
                <template v-slot:[`item.Requested`]="{ item }">
                  <span v-if="item.PRID != 0" class="text-no-wrap">
                  <v-icon
                  small
                  class="mx-1"
                  color="green"
                  >mdi-check</v-icon>
                  <v-icon
                  small
                  class="mx-1"
                  color="green"
                  @click="printPR(item.PRID)"
                  >mdi-file-document</v-icon>
                  </span>
                </template>
                <template v-slot:[`item.Revised`]="{ item }">
                  <v-icon
                  small
                  v-if="item.isRevised == 1"
                  class="mx-3"
                  color="green"
                  >mdi-check</v-icon>
                  
                </template>
                <template v-slot:[`item.Utilized`]="{ item }">
                  <span class="text-no-wrap">₱ {{formatPrice(item.Utilized)}}</span>
                </template>
                <template v-slot:[`item.Obligation`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(getObligationAmount(item)) }}</span>
                </template>
                <template v-slot:[`item.Balance`]="{ item }">
                  <span class="text-no-wrap">₱ {{formatPrice((parseFloat((parseFloat(item.UnitCost) * parseFloat(item.Qty)).toFixed(2)) - parseFloat(parseFloat(getObligationAmount(item))).toFixed(2)) - parseFloat((parseFloat(item.Utilized)).toFixed(2)))}}</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <!-- <v-icon
                small
                class="mr-2"
                color="red"
                   @click="viewPPMP(item)">
                delete
              </v-icon> -->
                        <v-btn
                          x-small
                          color="amber accent-4"
                          class="mr-2 white--text"
                          @click="obligate(item)"
                          v-if="$store.state.user.roleID == 7"
                        > 
                          Obligation
                        </v-btn>
                        <span v-if="ppmpData.PPMPStatusID == 11 || ppmpData.isOpen == 1">
                        <v-icon
                          small
                          v-if="item.isRevised == 0 && (item.PRID == 0 || parseFloat(getObligationAmount(item)) < parseFloat(item.Amount))"
                          color="green"
                          class="mr-2"
                          @click="editItem(item)"
                        >
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          small
                          color="green"
                          class="mr-2"
                          disabled
                          v-else
                        >
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          small
                          color="red"
                          v-if="item.isRevised == 0 && item.PRID == 0 && parseFloat(getObligationAmount(item)) == 0"
                          class="mr-2"
                          @click="deleteItem(item)"
                        >
                          mdi-delete
                        </v-icon>
                        <v-icon
                          small
                          color="red"
                          class="mr-2"
                          v-else
                          disabled
                        >
                          mdi-delete
                        </v-icon>
                        </span>
                </template>
                <template slot="body.append">
                  <tr style="background-color: #ddd;">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td align="center"><strong>TOTAL:</strong></td>
                    <td align="end"><strong class="text-no-wrap">₱ {{ formatPrice(amount_total) }}</strong></td>
                    <td></td>
                    <td></td>
                    <td align="end"><strong class="text-no-wrap">₱ {{ formatPrice(utilized_total) }}</strong></td>
                    <td align="end"><strong class="text-no-wrap">₱ {{ formatPrice(obligation_total) }}</strong></td>
                    <td align="end"><strong class="text-no-wrap">₱ {{ formatPrice(balance_total) }}</strong></td>
                    <td></td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        </v-card-text>
      </v-card>
      <v-btn fab dark large color="header" @click="viewRemarks()" fixed left bottom>
        <v-badge
          color="red darken-2"
          :content="unseenMsgs"
          :value="unseenMsgs"
        >
          <v-icon dark>mdi-email</v-icon>
        </v-badge>
    </v-btn>
    </v-dialog>
      <v-dialog v-model="addToPPMP" persistent max-width="700px">
      <v-card>
        <v-card-title>
          <span class="subtitle-1 font-weight-bold" v-if="editItemId"
            >Update Item</span
          >
          <span class="subtitle-1 font-weight-bold" v-else>Add Item</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent ref="Formref">
              <v-row>
                <!-- <v-col cols="12">
                  <v-alert
                    v-model="alertDialog"
                    type="info"
                    outlined
                    class="bg mt-5"
                  >
                    <v-row align="center" class="text-caption">
                      <v-col class="grow">
                        <span>
                          <b>Note: </b>Item prices had increased to 15%.
                          </span>
                        </v-col>
                      <v-col class="shrink">
                        <v-icon @click="alertDialog = false" color="info">mdi-close</v-icon>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col> -->
                <v-col cols="12" sm="12" md="12">
                  <span v-if="editItemId">
                    <v-list-item-content>
                      <v-list-item-title
                        ><b>{{ formData.ItemName }}</b></v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        formData.itemDescription
                      }}</v-list-item-subtitle>
                      <span
                        >₱ {{ formatPrice(formData.UnitCost) }} /
                        {{ formData.UnitMeasureDescription }}</span
                      >
                    </v-list-item-content>
                    <v-divider></v-divider>
                    <br />
                  </span>
                  <v-autocomplete
                    v-else
                    v-model="formData.ItemID"
                    color="green"
                    item-text="ItemName"
                    item-value="ItemID"
                    :rules="[formRules.required]"
                    :items="items"
                    :loading="itemsisLoading"
                    :search-input.sync="search"
                    dense
                    label="Item"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Search for an Item
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            data.item.ItemName
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            data.item.ItemDescription
                          }}</v-list-item-subtitle>
                          <span
                            >₱ {{ formatPrice(data.item.UnitCost) }} /
                            {{ data.item.UnitMeasureDescription }}</span
                          >
                        </v-list-item-content>
                      </template>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            data.item.ItemName
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            data.item.ItemDescription
                          }}</v-list-item-subtitle>
                          <span
                            >₱ {{ formatPrice(data.item.UnitCost) }} /
                            {{ data.item.UnitMeasureDescription }}</span
                          >
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                  <v-text-field
                    outlined
                    dense
                    v-model="formData.Qty"
                    color="green"
                    style="width: 190px"
                    :label="ppmpData.PPMPTypeID == 3 || formData.UnitCost == 1 ? 'Amount' : 'Quantity'"
                    :rules="[checkQtyandBalance(), formRules.noNegativeNumberAllowed, formRules.numberRequired, parseFloat(formData.Qty) >= editData.obligation]"
                    class="pa-0 mt-4"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <span v-if="parseFloat(editData.obligation) > 0">
              <b>Obligation:</b>
              <span :style="parseFloat(formData.Qty) >= editData.obligation ? 'color: green' : 'color: red'">
               ₱ {{formatPrice(parseFloat(editData.obligation))}}
              </span>
          </span>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="cancel()"> Cancel </v-btn>
          <v-btn
            v-if="!editItemId"
            :loading="loadingBtn"
            color="green darken-2"
            text
            @click="addItemToPPMP()"
          >
            Add Item
          </v-btn>
          <v-btn
            v-else
            color="blue darken-1"
            text
            @click="update()"
            :loading="loadingBtn"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="selectSchedDialog" persistent max-width="450px">
      <v-card>
        <v-card-title>
          <span class="">SCHEDULE/ MILESTONE OF ACTIVITIES </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent ref="Formref2">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    color="green"
                    label="Select Month"
                    v-model="formData.DateSchedules"
                    :rules="[formRules.required]"
                    :items="filteredMonths"
                    item-value="month"
                    item-text="description"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="selectSchedDialog = false">
            Cancel
          </v-btn>
          <v-btn
            :loading="loadingBtn"
            color="green darken-2"
            text
            @click="SaveDate()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
        <v-dialog v-model="dialogConfirmUnpublish" max-width="500px">
      <v-card>
        <v-card-actions>
          <v-row justify="center">
            <v-col align="center" cols="12">
              <v-icon dense x-large color="red darken-2" class="mt-4"
                >mdi-help-circle-outline</v-icon
              >
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-title class="text-h6 d-flex justify-center"
          >Do you want to Unpublish this PPMP?</v-card-title
        >
        <v-card-actions class="mt-4">
          <v-row justify="center">
            <v-col align="center" cols="6">
              <v-spacer></v-spacer>
              <v-btn color="red darken-2" text @click="dialogConfirmUnpublish = false"
                >Cancel</v-btn
              >
            </v-col>
            <v-col align="center" cols="6">
              <v-spacer></v-spacer>
              <v-btn color="green darken-2" text @click="confirmUnpublish()"
                >Continue</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

      <v-dialog v-model="dialogConfirmObligation" max-width="400px">
      <v-card>
        <v-card-actions>
          <v-row justify="center">
            <v-col align="center" cols="12">
              <v-icon dense x-large color="red darken-2" class="mt-4"
                >mdi-help-circle-outline</v-icon
              >
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-title class="text-h6 d-flex justify-center"
          >Are you sure to obligate this item <span style="color: red;">{{itemNameSelected}}</span>?</v-card-title
        >
        <v-card-actions class="mt-4">
          <v-row justify="center">
            <v-col align="center" cols="6">
              <v-spacer></v-spacer>
              <v-btn color="red darken-2" text @click="dialogConfirmObligation = false;"
                >Cancel</v-btn
              >
            </v-col>
            <v-col align="center" cols="6">
              <v-spacer></v-spacer>
              <v-btn color="green darken-2" text @click="confirmObligation()"
                >Continue</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmUnpublish" max-width="500px">
      <v-card>
        <v-card-actions>
          <v-row justify="center">
            <v-col align="center" cols="12">
              <v-icon dense x-large color="red darken-2" class="mt-4"
                >mdi-help-circle-outline</v-icon
              >
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-title class="text-h6 d-flex justify-center"
          >Do you want to Unpublish this PPMP?</v-card-title
        >
        <v-card-actions class="mt-4">
          <v-row justify="center">
            <v-col align="center" cols="6">
              <v-spacer></v-spacer>
              <v-btn color="red darken-2" text @click="dialogConfirmUnpublish = false"
                >Cancel</v-btn
              >
            </v-col>
            <v-col align="center" cols="6">
              <v-spacer></v-spacer>
              <v-btn color="green darken-2" text @click="confirmUnpublish()"
                >Continue</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

      <v-dialog v-model="dialogObligations" width="1000">
        <v-card>
          <v-card-title class="white--text text-h5 green darken-2">
            Obligations
            <v-spacer></v-spacer>
            <v-btn color="white darken-2" text @click="dialogObligations = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-container>
            <v-form @submit.prevent ref="Formref">
            <v-row class="">
              <v-col cols="12">
                <v-data-table
                  :headers="obligation_headers"
                  :items="obligationList"
                  disable-pagination
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <span class="green--text text--darken-2"
                        >Obligations</span
                      >
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-2"
                        dark
                        @click="dialogAddObligation = true"
                      >
                      <v-icon left>
                        mdi-plus
                      </v-icon>
                        Add Obligation
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="(item, index) in items" :key="index">
                        <td>
                          <v-edit-dialog
                            large
                            :return-value.sync="item.ObligationDescription"
                          >
                            {{ item.ObligationDescription }}
                          <template v-slot:input>
                              <v-textarea v-model="item.ObligationDescription">
                              </v-textarea>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td style="max-width: 200px" :class="parseFloat(parseFloat(item.Amount).toFixed(2)) > parseFloat(parseFloat(selectedAmount).toFixed(2)) ? 'red--text' : ''">
                          <v-edit-dialog
                            large
                            :return-value.sync="item.Amount"
                          >
                            {{ item.Amount }}
                            <template v-slot:input>
                              <v-textarea v-model="item.Amount">
                              </v-textarea>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td style="max-width: 200px">
                            {{ formatDate(item.DateCreated) }}
                        </td>
                        <td>
                          <v-btn 
                          small
                          color="red"
                          class="white--text"
                          @click="removeObligation(item)">
                            Delete
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-if="obligationList.length == 0" class="text-center">
                      <tr>
                        <td :colspan="headers.length" >
                          No Records Yet
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>


            </v-form>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>
          
          <v-card-actions>
            <span>Total: <b>{{formatPrice(obligationListTotal)}}</b></span>
            <v-spacer></v-spacer>
            <v-btn color="red darken-2" text @click="dialogObligations = false">
              Cancel
            </v-btn>
            <v-btn color="green darken-2" text @click="saveObligations()" :disabled="obligationList.length < 1 && deleteObligationList.length < 1">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogAddObligation" width="500">
        <v-card>
          <v-card-title class="white--text text-h5 green darken-2">
            Add New Obligation
            <v-spacer></v-spacer>
            <v-btn color="white darken-2" text @click="dialogAddObligation = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-container>
            <v-form @submit.prevent ref="FormrefObligation">
            <v-row class="">
              <v-col cols="12">
                <span>Item Amount: <b>P {{formatPrice(selectedAmount)}}</b></span>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    dense
                    v-model="obligation.ObligationDescription"
                    :rules="[formRules.required]"
                    label="Description"
                    
                  ></v-text-field>
                <v-text-field
                    dense
                    v-model="obligation.Amount"
                    :rules="[formRules.required, formRules.numberRequired, parseFloat(parseFloat(obligation.Amount).toFixed(2)) <= parseFloat(parseFloat(selectedAmount).toFixed(2))]"
                    label="Obligation Amount"
                  ></v-text-field>
              </v-col>
            </v-row>


            </v-form>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-2" text @click="dialogAddObligation = false">
              Cancel
            </v-btn>
            <v-btn color="green darken-2" class="white--text" @click="addObligation()">
              Add Obligation
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline mt-5">Confirmation</v-card-title>
            <div class="mx-6">
              <strong>Are you sure you want to delete this item?</strong> <br />
              <b>Item Name: </b> {{itemToDelete.ItemName}}
            </div>
            <v-card-actions class="mt-5">
              <v-spacer></v-spacer>
              <v-btn color="grey darken-2" outlined @click="dialogDelete = false">Cancel</v-btn>
              <v-btn color="red darken-2 white--text" @click="confirmDeleteItem()">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
<PSURemarks :data="ppmpRemarksData" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>
    
    <script>
    import PSURemarks from './PSURemarks.vue'
export default {
  components: {
      PSURemarks
    },
  props: {
    ppmpData: {},
  },
  data: () => ({
    ppmpDetails: [],
    itemsisLoading: false,
    items: [],
    search: null,
    ppmpUsedAmount: null,
    headers: [
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "itemDescription", sortable: false },
      { text: "Quantity", value: "Qty", sortable: false, align: "center" },
      { text: "Unit Meas.", value: "UnitMeasureDescription", sortable: false, align: "center" },
      { text: "Unit Cost", value: "UnitCost", sortable: false, align: "end" },
      { text: "Amount", value: "Amount", sortable: false, align: "end" },
      { text: "Requested", value: "Requested", sortable: false, align: "center" },
      { text: "Revised", value: "Revised", sortable: false, align: "center" },
      { text: "Utilized", value: "Utilized", sortable: false, align: "end" },
      { text: "Obligation", value: "Obligation", sortable: false, align: "end" },
      { text: "Balance", value: "Balance", sortable: false, align: "end" },
      { text: "Action", value: "actions", sortable: false },
    ],
    obligation_headers: [
       {
        text: "Description",
        align: "start",
        sortable: false,
        value: "ObligationDescription",
      },
      { text: "Amount", value: "Amount", sortable: false },
      { text: "Date Obligated", value: "DateCreated", sortable: false },
      { text: "Action", value: "actions", sortable: false },
    ],
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    filteredMonths: [],
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    addToPPMP: false,
    loading: false,
    ppmp: [],
    dialogAddItem: false,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    options: {},
    isLoading: false,
    itemList: [],
    formData: {
      ppmpID: null,
      ItemID: null,
      Qty: null,
      ItemName: null,
      UnitCost: null,
      UnitMeasureDescription: null,
      itemDescription: null,
      DateSchedules: null,
    },
    loadingBtn: false,
    editItemId: null,
    editItemQty: null,
    selectSchedDialog: false,
    dialogConfirmUnpublish: false,
    toUnpublishPPMPID: null,
    dialogConfirmObligation: false,
    ppmpItemSelected: null,
    obligationSelected: null,
    itemNameSelected: '',
    dialogObligations: false,
    obligationList: [],
    obligation: [],
    obligations: [],
    dialogAddObligation: false,
    AddPPMPItemID: null,
    obligationListTotal: 0,
    deleteObligationList: [],
    selectedAmount: 0,
    itemToDelete: [],
    dialogDelete: false,
    ppmpRemarksData: [],
    unseenMsgs: 0,
    editData: {
      obligation: 0
    },
    amount_total: 0,
    utilized_total: 0,
    obligation_total: 0,
    balance_total: 0,
  }),
  watch: {
    search(val) {
      this.itemsisLoading = true;
      setTimeout(() => {
        if (val == this.search) {
          this.SearchItem();
        }
      }, 2000);
    },
    options: {
      handler() {
        if (this.totalCount > 0) {
          this.getItems();
        }
      },
      deep: true,
    },
    ppmpData: {
      handler(data) {
        // console.log(data);
        if (data.PPMPID) {
          this.deleteObligationList = [];
          this.dialogAddItem = true;
          this.getItems();
          this.getUnseenMsgs();
          this.getFilteredMonths(data.BudgetDate, data.BudgetDateEnd)
        }
      },
      deep: true,
    },
    obligationList: {
      handler(data) {
        let total = 0;
        data.forEach(item => {
          total += parseFloat(item.Amount);
        });
          this.obligationListTotal = total;
      },
      deep: true,
    },
  },
  mounted() {
        this.eventHub.$on('closePSURemarks', ()=>{
          this.ppmpRemarksData = [];
         this.getUnseenMsgs();
        });
  },
  beforeDestroy() {
         this.eventHub.$off('closePSURemarks');
    },
  methods: {
    viewRemarks(){
      this.ppmpRemarksData = {
        id: this.ppmpData.PPMPID,
        DocType: 1,
        RemarksID: this.ppmpData.RemarksID
      };
      if(this.unseenMsgs > 0){
        let data = new FormData();
        data.append("RemarksID", this.ppmpData.RemarksID);
        this.axiosCall("/remarks/seen", "POST", data).then((res) => {
          console.log(res)
          this.unseenMsgs = 0
        });
      }
    },
    getUnseenMsgs(){
      this.unseenMsgs = 0;
        let data = new FormData();
        data.append("RemarksID", this.ppmpData.RemarksID);
        this.axiosCall("/getUnseenMsgs", "POST", data).then((res) => {
        this.unseenMsgs = res.data.data
      });
    },
    swithcChange() {
      console.log(this.ppmpData.isOpen);

      let data = new FormData();
      data.append("PPMPID", this.ppmpData.PPMPID);
      data.append("swithcVal", this.ppmpData.isOpen);
      this.axiosCall("/signatories/PPMP/open/close", "POST", data).then(
        (res) => {
          console.log(res);
        }
      );
    },
    whoCanUse() {
      return (
        (this.$store.state.user.roleID != 7 &&
          this.$store.state.user.roleID != 8 &&
          this.$store.state.user.roleID != 9 &&
          this.$store.state.user.roleID != 10) ||
        !this.$route.name
      );
    },
    ableToUnpublish() {
      let res = false;
        this.itemList.forEach(item => {
          if (item.PRstatus < 4) {
          res = true;
        }
        });
      return res;
    },
    unpublish(PPMPID) {
      this.dialogConfirmUnpublish = true;
      this.toUnpublishPPMPID = PPMPID;
    },
    confirmUnpublish(){
      let data = new FormData();
      data.append("PPMPID", this.toUnpublishPPMPID);
      this.axiosCall("/ppmp/unpublish", "POST", data).then((res) => {
        if(res.data.status) {
            this.toUnpublishPPMPID = null;
            this.dialogConfirmUnpublish = false;
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = 'success';
            this.fadeAwayMessage.message = 'PPMP successfully unpublished!';
            this.fadeAwayMessage.header = 'System Message';
            this.dialogAddItem = false;
            }else {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = 'error';
            this.fadeAwayMessage.message = res.data.message;
            this.fadeAwayMessage.header = 'System Message';
            }
      });
    },
    publish(item) {
      let data = new FormData();
      data.append("PPMPID", item.PPMPID);
      data.append("Amount", this.ppmpUsedAmount);
      data.append("isDBM", item.isDBM);
      data.append("BudgetID", item.BudgetID);
      this.axiosCall("/ppmp/publish", "POST", data).then((res) => {
        this.clickCloseMainDialog();
        if (res.data.status) {
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "success";
          this.fadeAwayMessage.message = "Published Successfully!";
          this.fadeAwayMessage.header = "System Message";
        } else {
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.message = "Something went wrong try again!";
          this.fadeAwayMessage.header = "System Message";
        }
      });
    },
    SaveDate() {
      if(this.$refs.Formref2.validate()){
        if (this.formData.DateSchedules) {
        this.selectSchedDialog = false;
        this.addToPPMP = true;
      }
      }
    },
    addItemBtn() {
      if (this.ppmpData.isDBM) {
        this.formData.DateSchedules = "January";
        this.addToPPMP = true;
      } else {
        this.selectSchedDialog = true;
      }
    },
    editItem(data) {
      if (
        this.ppmpData.PPMPStatusID == 11 &&
        this.ppmpData.isOpen == 1 &&
        this.whoCanUse()
      ) {
        if(this.ppmpData.PPMPTypeID == 3 || parseFloat(this.formatQty(data.Qty)) == 1){
          this.editItemQty = parseFloat(this.formatQty(data.UnitCost));
          this.formData.Qty = parseFloat(this.formatQty(data.UnitCost));
          this.formData.UnitCost = data.Qty;
        }
        else{
          this.editItemQty = data.Qty;
          this.formData.Qty = data.Qty;
          this.formData.UnitCost = data.UnitCost;
        }
        data.obligation = this.getObligationAmount(data)
        this.editData = data;
        this.editItemId = data.ppmpItemID;
        this.formData.ItemName = data.ItemName;
        this.formData.UnitMeasureDescription = data.UnitMeasureDescription;
        this.formData.itemDescription = data.itemDescription;
        this.formData.ItemID = data.ItemID;
        this.formData.DateSchedules = data.DateSchedules
        this.selectSchedDialog = true;
      }
    },
    deleteItem(item) {
      if (
        this.ppmpData.PPMPStatusID == 11 &&
        this.ppmpData.isOpen == 1 &&
        this.whoCanUse()
      ) {
      this.itemToDelete = item;
      this.dialogDelete = true;
      }
    },
    confirmDeleteItem(){
      if (
        this.ppmpData.PPMPStatusID == 11 &&
        this.ppmpData.isOpen == 1 &&
        this.whoCanUse()
      ) {
        let data = new FormData();
        data.append("deleteID", this.itemToDelete.ppmpItemID);
        this.axiosCall("/delete/ppmp/item", "POST", data).then((res) => {
          console.log(res);
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "success";
          this.fadeAwayMessage.message = "Item Successfully Deleted from PPMP!";
          this.fadeAwayMessage.header = "System Message";
          this.itemToDelete = [];
          this.dialogDelete = false;
          this.getItems();
        });
      }
    },
    checkQtyandBalance() {
      let balance =
        parseFloat(this.ppmpData.Amount) - parseFloat(this.ppmpUsedAmount);
      if (this.ppmpData.isDBM && parseFloat(this.ppmpData.Amount) < 1) {
        balance =
          parseFloat(this.ppmpData.budgetAmount) -
          parseFloat(this.ppmpUsedAmount);
      }
      let itemID = this.formData.ItemID;
      let qty = this.formData.Qty;
      if (!itemID) {
        return true;
      }
      let itemAmount = 0;
      if (this.editItemId) {
        itemAmount =
          parseFloat(this.formData.UnitCost) * parseFloat(this.formData.Qty) -
          parseFloat(this.formData.UnitCost) * parseFloat(this.editItemQty);
      } else {
        this.items.forEach((element) => {
          if (element.ItemID == itemID) {
            itemAmount = element.UnitCost * qty;
          }
        });
      }
      if (balance && itemAmount) {
        balance = balance.toFixed(2);
        itemAmount = itemAmount.toFixed(2);
      }
      return (
        parseFloat(balance) >= parseFloat(itemAmount) || "Insufficient Balance"
      );
    },
    SearchItem() {
      let data = new FormData();
      data.append("search", this.search);
      data.append("CategoryTypeID", this.ppmpData.PPMPTypeID);
      this.axiosCall("/search/new/item", "POST", data).then((res) => {
        this.items = res.data.data.result;
        this.itemsisLoading = false;
        this.$forceUpdate();
      });
    },
    clickCloseMainDialog() {
      this.formData.DateSchedules = null;
      this.totalCount = 0;
      this.items = [];
      this.dialogAddItem = false;
      this.eventHub.$emit("closePPMPModal", false);
    },
    print(data) {
      if(data.PPMPTypeID == 3){
        window.open(
          this.apiUrl + "/ppmp/report/lib/" + data.PPMPID + "/" + data.BudgetID
        );
      }
      else{
        window.open(
          this.apiUrl + "/ppmp/report/" + data.PPMPID + "/" + data.BudgetID
        );
      }
    },
    printWithOldPPMP(data) {
      window.open(
        this.apiUrl + "/ppmp/report/" + data.PPMPID + "/" + data.BudgetID + "/revised"
      );
    },
    printWithOldLIB(data) {
      window.open(
        this.apiUrl + "/ppmp/report/lib/" + data.PPMPID + "/" + data.BudgetID + "/revised"
      );
    },
    printPR(PRID) {
      window.open(this.apiUrl + "/pr/report/" + PRID);
    },
    cancel() {
      this.editItemQty = null;
      (this.editItemId = null), this.$refs.Formref.reset();
      this.editData = {
        obligation: 0
      }
      this.formData.ItemName = null;
      this.formData.UnitMeasureDescription = null;
      this.formData.itemDescription = null;
      this.formData.ItemID = null;
      this.formData.DateSchedules = null
      this.addToPPMP = false;
    },
    update() {
      if (this.$refs.Formref.validate()) {
        this.loadingBtn = true;
        let data = new FormData();
        data.append("Qty", this.formData.Qty);
        data.append("ppmpItemID", this.editItemId);
        data.append("itemID", this.formData.ItemID);
        data.append("UnitCost", this.formData.UnitCost);
        data.append("DateSchedules", this.formData.DateSchedules);
        data.append("PPMPTypeID", this.ppmpData.PPMPTypeID);
        this.axiosCall("/ppmp/edit/item", "POST", data).then((res) => {
          this.loadingBtn = false;
          if (res.data.status) {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "Item Successfully Updated!";
            this.fadeAwayMessage.header = "System Message";
            this.cancel();
            this.getItems();
          } else {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = "Something went wrong try again!";
            this.fadeAwayMessage.header = "System Message";
          }
        });
      }
    },
    addItemToPPMP() {
      if (this.$refs.Formref.validate()) {
        let UnitCost = 0;
        this.items.forEach((element) => {
          if (element.ItemID == this.formData.ItemID) {
            UnitCost = element.UnitCost;
          }
        });
        this.loadingBtn = true;
        let data = new FormData();
        data.append("Qty", this.formData.Qty);
        data.append("ppmpID", this.ppmpData.PPMPID);
        data.append("itemID", this.formData.ItemID);
        data.append("UnitCost", UnitCost);
        data.append("DateSchedules", this.formData.DateSchedules);
        data.append("PPMPTypeID", this.ppmpData.PPMPTypeID);
        this.axiosCall("/ppmp/add/item", "POST", data).then((res) => {
          this.loadingBtn = false;
          if (res.data.status) {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "Item Successfully Added!";
            this.fadeAwayMessage.header = "System Message";
            this.cancel();
            this.getItems();
          } else {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = res.data.message;
            this.fadeAwayMessage.header = "System Message";
          }
        });
      }
    },
    addItems(data) {
      this.formData.ppmpID = data.PPMPID;
      this.dialogAddItem = true;
    },
    addItemQty(data) {
      this.formData.ItemID = data.ItemID;
      this.formData.UnitMeasure = data.UnitMeasureDescription;
      this.addToPPMP = true;
    },
    initialize() {
      this.loading = true;
      this.axiosCall("/get/PPMP", "GET").then((res) => {
        this.loading = false;
        this.ppmp = res.data.data.result;
      });
    },
    getItems() {
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("BudgetID", this.ppmpData.BudgetID);
      data.append("PPMPID", this.ppmpData.PPMPID);
      data.append("isDBM", this.ppmpData.isDBM);
      this.axiosCall("/getPPMPItem", "POST", data).then((res) => {
        this.loading = false;
        this.itemList = res.data.data.result;
        this.itemList.forEach(item => {
          if(item.ParentPRID || item.ParentPRID > 0){
            item.Utilized = parseFloat(item.UnitCost) * parseFloat(item.Qty);
          }
        });
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
        this.ppmpUsedAmount = parseFloat((res.data.data.useAmount).toFixed(2));
        this.ppmpDetails = res.data.data.details;
        this.obligations = res.data.data.obligations;
        if(this.ppmpDetails.isRevised == 1){
        this.obligations.forEach(item => {
          this.ppmpUsedAmount = this.ppmpUsedAmount + parseFloat(parseFloat(item.Amount).toFixed(2));
        });
        }
        this.amount_total = 0;
        this.utilized_total = 0;
        this.obligation_total = 0;
        this.balance_total = 0;
        this.itemList.forEach(item => {
          this.amount_total = this.amount_total + (parseFloat(item.UnitCost) * parseFloat(item.Qty))
          this.utilized_total = this.utilized_total + parseFloat(item.Utilized)
          this.obligation_total = this.obligation_total + parseFloat(this.getObligationAmount(item))
          this.balance_total = this.balance_total + ((parseFloat((parseFloat(item.UnitCost) * parseFloat(item.Qty)).toFixed(2)) - parseFloat(parseFloat(this.getObligationAmount(item))).toFixed(2)) - parseFloat((parseFloat(item.Utilized)).toFixed(2)))
        });
      });
    },
    obligate(data){
      this.obligationList = [];
      this.obligations.forEach(item => {
        if(item.PPMPItemID == data.ppmpItemID){
          this.obligationList.push(item);
        }
      });
      this.AddPPMPItemID = data.ppmpItemID;
      this.selectedAmount = parseFloat(data.Qty) * parseFloat(data.UnitCost);
      this.dialogObligations = true;
    },
    getObligationAmount(data){
      let total = 0;
        this.obligations.forEach(item => {
          if(item.PPMPItemID == data.ppmpItemID){
            total += parseFloat(item.Amount);
          }
        });
        return total;
    },
    addObligation(){
      if (this.$refs.FormrefObligation.validate()) {
        let today = new Date();
        this.obligationList.push(
          {
            'PPMPItemID': this.AddPPMPItemID,
            'ObligationDescription': this.obligation.ObligationDescription,
            'Amount': this.obligation.Amount,
            'DateCreated': this.formatDate(today)
          });
        this.dialogAddObligation = false;
        this.$refs.FormrefObligation.reset();
      }
    },
    saveObligations(){
      if(this.obligationList.reduce((n, {Amount}) => parseFloat(n) + parseFloat(Amount), 0) <= parseFloat(parseFloat(this.selectedAmount).toFixed(2))){
      let data = new FormData();
      data.append("obligations",  JSON.stringify(this.obligationList));
      data.append("deleteObligations",  JSON.stringify(this.deleteObligationList));
      this.axiosCall("/obligation/save", "POST", data).then((res) => {
        if (res.data.status) {
          this.dialogAddObligation = false;
          this.dialogObligations = false;
          this.getItems();
          this.deleteObligationList = [];
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "success";
          this.fadeAwayMessage.message = "Obligations Successfully Saved!";
          this.fadeAwayMessage.header = "System Message";
          this.$refs.FormrefObligation.reset();
        } else {
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.message = res.data.message;
          this.fadeAwayMessage.header = "System Message";
        }
      });
      }
      else{
        this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.message = "Unable to save the obligation. Please check the details.";
          this.fadeAwayMessage.header = "System Message";
      }
    },
    removeObligation(item){
      let index = this.obligationList.indexOf(item);
      if (index > -1) {
        if(typeof item.ObligationID !== 'undefined'){
        this.deleteObligationList.push(item);
        }
        this.obligationList.splice(index, 1);
      }
    },
    getFilteredMonths(startDate, endDate) {
      this.filteredMonths = [];
      var start      = startDate.split('-');
      var end        = endDate.split('-');
      var startYear  = parseInt(start[0]);
      var endYear    = parseInt(end[0]);

        for(var i = startYear; i <= endYear; i++) {
          var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
          var startMon = i === startYear ? parseInt(start[1])-1 : 0;
          for(var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
            var month = j+1;
            var displayMonth = month < 10 ? '0'+month : month;
            this.filteredMonths.push({month: this.months[displayMonth - 1], description: this.months[displayMonth - 1] + ' ' + i})
          }
        }
    },
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.ppmpModal {
  overflow-x: hidden !important;
}
</style>